<template>
  <div>
    <top-header v-bind:title="$t('spots')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('new')"
                     v-bind:to="{ name: 'administration-spot-add' }" />
    </top-header>
    <v-container>
      <spot-administration-list-card v-bind:spots="spots" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton'
  import TopHeader from '@/components/common/TopHeader'
  import SpotAdministrationListCard from '@/components/SpotAdministrationListCard'

  export default {
    components: {
      HeaderButton,
      SpotAdministrationListCard,
      TopHeader
    },
    computed: {
      ...mapGetters([ 'spots' ])
    },
    async created() {
      await this.loadSpots()
    },
    methods: {
      ...mapActions([ 'loadSpots' ])
    },
    name: 'Spots'
  }
</script>

<i18n>
  {
    "de": {
      "new": "Neu",
      "spots": "Spots"
    },
    "en": {
      "categories": "Categories",
      "spots": "Spots"
    }
  }
</i18n>