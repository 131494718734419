import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{staticClass:"py-0",attrs:{"color":"secondary","dark":""}},[_c(VListItem,{staticClass:"deep-orange lighten-1",attrs:{"exact":"","to":{ name: 'home' }}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('back')))])],1)],1),_c(VListItem,{attrs:{"to":{ name: 'administration-food-trucks' }}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-truck")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('foodTrucks')))])],1)],1),(_vm.hasRole('FoodTruckFinderAdministrator'))?_c(VListItem,{attrs:{"to":{ name: 'administration-partners' }}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-handshake")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('partners')))])],1)],1):_vm._e(),(_vm.hasRole('FoodTruckFinderAdministrator'))?_c(VListItem,{attrs:{"to":{ name: 'administration-categories' }}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-tag")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('categories')))])],1)],1):_vm._e(),(_vm.hasRole('FoodTruckFinderAdministrator'))?_c(VListItem,{attrs:{"to":{ name: 'administration-spots' }}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-fire")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('spots')))])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }