<template>
  <div>
    <top-header v-bind:title="$t('partners')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-if="hasRole('FoodTruckFinderAdministrator')"
                     v-bind:label="$t('new')"
                     v-bind:to="{ name: 'administration-food-truck-add', query: { partner: true} }" />
    </top-header>
    <v-container>
      <food-truck-administration-list-card v-bind:title="$t('partners')"
                                           v-bind:foodTrucks="partners" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton'
  import TopHeader from '@/components/common/TopHeader'
  import FoodTruckAdministrationListCard from '@/components/FoodTruckAdministrationListCard'

  export default {
    components: {
      FoodTruckAdministrationListCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      partners() {
        return this.filteredFoodTrucks.filter(f => f.partner)
      },
      ...mapGetters({
        filteredFoodTrucks: 'filteredFoodTrucks',
        hasRole: 'auth/hasRole'
      })
    },
    async created() {
      await this.loadFoodTrucks()
    },
    methods: {
      ...mapActions([
        'loadFoodTrucks'
      ])
    },
    name: 'Partners'
  }
</script>

<i18n>
  {
    "de": {
      "partners": "Partner",
      "new": "Neu"
    },
    "en": {
      "partners": "Partners",
      "new": "New"
    }
  }
</i18n>