<template>
  <div>
    <new-app-dialog ref="newAppDialog"
                    v-model="newAppDialog" />

    <food-truck-dialog ref="foodTruckDialog"
                       v-bind:food-truck="selectedFoodTruck"
                       v-bind:selected-date="selectedDate"
                       v-model="foodTruckDialog" />

    <v-toolbar absolute
               class="mx-4 my-4"
               floating
               rounded="lg">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>{{ `mdi-${selectedCategoryIcon}`}}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group color="primary"
                             v-model="selectedCategory">
            <v-list-item v-bind:key="category.id"
                         v-bind:value="category.id"
                         v-for="category in categories">
              <v-list-item-icon>
                <v-icon>{{ `mdi-${category.icon}` }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ category.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-clock-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group color="primary"
                             v-model="selectedDate">
            <v-list-item v-bind:key="dateItem.value"
                         v-bind:value="dateItem.value"
                         v-for="dateItem in dateItems">
              <v-list-item-title>{{ dateItem.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-btn icon
             v-bind:color="errorLocate ? 'deep-orange': null"
             v-bind:loading="loadingLocate"
             v-on:click="locate">
        <v-icon>mdi-crosshairs-gps</v-icon>
      </v-btn>

      <v-combobox hide-details
                  flat
                  item-text="title"
                  item-value="id"
                  light
                  prepend-inner-icon="mdi-magnify"
                  solo
                  v-bind:items="sortedFoodTrucks"
                  v-bind:loading="loadingSearch"
                  v-on:focus="search = null"
                  v-model="search"
                  v-on:change="executeSearch">
        <template v-slot:item="{ item }">
          <v-list-item-avatar v-bind:color="item.logoId == null ? 'primary' : null">
            <v-img v-bind:src="attachmentUrl(item.logoId)"
                   v-if="item.logoId != null">
            </v-img>
            <v-icon color="white"
                    dark
                    v-else>
              mdi-truck
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle v-bind:class="todaysSlots(item).length > 0 ? 'light-green--text' : null">
              <v-icon class="mr-2"
                      small>
                {{ `mdi-${categoryIcon(item.categoryId)}` }}
              </v-icon>
              <v-icon class="mr-2"
                      small
                      v-if="item.secondaryCategoryId != null">
                {{ `mdi-${categoryIcon(item.secondaryCategoryId)}` }}
              </v-icon>
              {{ todaysSlotsText(item) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-combobox>
    </v-toolbar>

    <map-component class="fill-height"
                   ref="mapComponent"
                   v-bind:annotations="annotations"
                   v-on:annotation-selected="annotationSelected" />

    <div class="button-container">
      <v-btn class="mb-4"
             dark
             fab
             v-bind:to="{ name: 'partner-list' }">
        <v-icon>mdi-handshake</v-icon>
      </v-btn>

      <v-btn class="mb-4"
             dark
             fab
             v-bind:to="{ name: 'spots' }">
        <v-icon>mdi-fire</v-icon>
      </v-btn>

      <v-btn dark
             fab
             v-bind:to="{ name: 'list' }">
        <v-icon>mdi-format-list-text</v-icon>
      </v-btn>
    </div>

  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import moment from 'moment'

  import FoodTruckDialog from '@/components/FoodTruckDialog'
  import MapComponent from '@/components/MapComponent'
  import NewAppDialog from '@/components/NewAppDialog'


  export default {
    components: {
      FoodTruckDialog,
      MapComponent,
      NewAppDialog
    },
    computed: {
      annotations() {
        return this.flattenedFoodTrucks.filter(f => f.latitude != null && f.longitude != null).map(f => ({
          id: f.id,
          color: this.categoryColor(f.categoryId),
          clusteringIdentifier: `${this.categoryId}`,
          title: f.title,
          logoUrl: f.logoId != null ? this.attachmentUrl(f.logoId) : null,
          latitude: f.latitude,
          longitude: f.longitude
        }))
      },
      dateItems() {
        return Array.from([0, 1, 2, 3, 4, 5, 6, 7], d => ({
          text: moment().add(d, 'd').locale(this.$i18n.locale).format('dddd, L'),
          value: moment().add(d, 'd').format("YYYY-MM-DD")
        }))
      },
      filteredFoodTrucks() {
        let filteredFoodTrucks = this.foodTrucks

        if (this.selectedCategory != null) {
          filteredFoodTrucks = filteredFoodTrucks.filter(f => f.categoryId === this.selectedCategory || f.secondaryCategoryId == this.selectedCategory)
        }

        const dayOfWeek = moment(this.selectedDate).isoWeekday()
        const week = moment(this.selectedDate).isoWeek()
        const isEvenWeek = week % 2 === 0
        const isOddWeek = week % 2 === 1

        filteredFoodTrucks = filteredFoodTrucks.filter(f => f.timeSlots.some(t => t.dayOfWeek === dayOfWeek && (t.evenWeeks && isEvenWeek || t.oddWeeks && isOddWeek)))

        return filteredFoodTrucks
      },
      flattenedFoodTrucks() {
        const flattenedFoodTrucks = []

        const dayOfWeek = moment(this.selectedDate).isoWeekday()
        const week = moment(this.selectedDate).isoWeek()
        const isEvenWeek = week % 2 === 0
        const isOddWeek = week % 2 === 1

        this.filteredFoodTrucks.forEach(f => {
          const timeSlots = f.timeSlots.filter(t => !t.disabled && t.dayOfWeek === dayOfWeek && (t.evenWeeks && isEvenWeek || t.oddWeeks && isOddWeek))

          timeSlots.forEach(t => {
            flattenedFoodTrucks.push({
              id: f.id,
              categoryId: f.categoryId,
              title: f.title,
              latitude: t.latitude,
              longitude: t.longitude,
              logoId: f.logoId
            })
          })
        })

        return flattenedFoodTrucks
      },
      sortedFoodTrucks() {
        return [...this.foodTrucks].sort((a, b) => Math.min(this.todaysSlots(a).length, 1) > Math.min(this.todaysSlots(b).length, 1) ? -1 : 1)
      },
      selectedCategoryIcon() {
        if (this.selectedCategory == null) {
          return 'tag'
        }

        return this.category(this.selectedCategory).icon
      },
      selectedFoodTruck() {
        if (this.foodTruckId == null) {
          return {}
        }

        return this.foodTruck(this.foodTruckId)
      },
      ...mapGetters([
        'attachmentUrl',
        'categoryColor',
        'categoryIcon',
        'categories',
        'category',
        'foodTruck',
        'foodTrucks'
      ])
    },
    async created() {
      await this.loadCategories()
      await this.loadFoodTrucks()

      if (this.$route.query?.foodTruckId != null) {
        const id = parseInt(this.$route.query.foodTruckId)

        if (this.foodTruck(id) != null) {
          this.foodTruckId = id
          this.$refs.foodTruckDialog.open()
        }
      }

      this.selectedDate = moment().format("YYYY-MM-DD")
    },
    data: () => ({
      errorLocate: false,
      newAppDialog: false,
      foodTruckDialog: null,
      foodTruckId: null,
      loadingLocate: false,
      loadingSearch: false,
      search: null,
      selectedCategory: null,
      selectedDate: null
    }),
    mounted() {
      if (this.$route.query?.foodTruckId == null && !this.$route.query.chromeless) {
        //this.$refs.newAppDialog.open()
      }
    },  
    methods: {
      annotationSelected({ id }) {
        this.foodTruckId = id

        this.$refs.foodTruckDialog.open()
      },
      async executeSearch(search) {
        if (search == null) {
          return
        }

        if (typeof search === 'object') {
          this.foodTruckId = search.id
          this.search = search.title

          this.$refs.foodTruckDialog.open()

          return
        }

        document.activeElement.blur();

        this.loadingSearch = true

        try {
          const query = this.search.toLowerCase()

          const foodTruck = this.flattenedFoodTrucks.find(f => f.title.toLowerCase().search(query) !== -1)

          let region = null

          if (foodTruck != null) {
            region = {
              latitude: foodTruck.latitude,
              longitude: foodTruck.longitude,
              latitudeDelta: 0.005,
              longitudeDelta: 0.005
            }
          } else {
            region = await this.searchPlace({ query })
          }

          this.$refs.mapComponent.setRegion(region.latitude, region.longitude, region.latitudeDelta, region.longitudeDelta)
        } finally {
          this.loadingSearch = false
        }
      },
      locate() {
        this.loadingLocate = true
        this.search = null

        navigator.geolocation.getCurrentPosition(position => {
          this.loadingLocate = false

          this.$refs.mapComponent.setRegion(position.coords.latitude, position.coords.longitude, 0.01, 0.01)
        }, error => {
          this.loadingLocate = false

          this.errorLocate = true
        }, {
          enableHighAccuracy: true
        })
      },
      todaysSlots(foodTruck) {
        const dayOfWeek = moment(this.selectedDate).isoWeekday()
        const week = moment(this.selectedDate).isoWeek()
        const isEvenWeek = week % 2 === 0
        const isOddWeek = week % 2 === 1

        const timeSlots = foodTruck.timeSlots.filter(t => !t.disabled && t.dayOfWeek === dayOfWeek && (t.evenWeeks && isEvenWeek || t.oddWeeks && isOddWeek))

        return timeSlots.sort((a, b) => a.from.localeCompare(b.from))

      },
      todaysSlotsText(foodTruck) {
        const slots = this.todaysSlots(foodTruck)

        if (slots.length == 0) {
          return this.$t('closed')
        }

        const formatter = new Intl.ListFormat(this.$i18n.locale, { style: 'long', type: 'conjunction' });

        const texts = slots.map(s => `${s.from}-${s.to}`)

        return this.$t('openBetween', { slots: formatter.format(texts) })
      },
      ...mapActions([
        'loadCategories',
        'loadFoodTrucks',
        'searchPlace'
      ])
    },
    name: 'Home',
    watch: {
      foodTruckDialog(newValue, oldValue) {
        if (!newValue && oldValue) {
          this.$refs.mapComponent.deselect()
        }
      }
    }
  }
</script>

<style scoped>
  .button-container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: min-content;
    margin: 0 28px 72px 0;
  }
</style>

<i18n>
  {
    "de": {
      "closed": "Geschlossen",
      "openBetween": "Von {slots} geöffnet"
    },
    "en": {
      "closedToday": "Closed today",
      "openBetween": "Open between {slots}"
    }
  }
</i18n>