<template>
  <v-list class="py-0"
          color="secondary"
          dark>
    <v-list-item exact
                 v-bind:to="{ name: 'home' }">
      <v-list-item-icon>
        <v-icon color="accent lighten-3">mdi-map-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('map') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item exact
                 v-bind:to="{ name: 'list' }">
      <v-list-item-icon>
        <v-icon color="accent lighten-3">mdi-format-list-text</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('list') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item exact
                 v-bind:to="{ name: 'partner-list' }">
      <v-list-item-icon>
        <v-icon color="accent lighten-3">mdi-handshake</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('partners') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item exact
                 v-bind:to="{ name: 'spots' }">
      <v-list-item-icon>
        <v-icon color="accent lighten-3">mdi-fire</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('spots') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item target="_blank"
                 v-bind:href="webUrl">
      <v-list-item-icon>
        <v-icon color="accent lighten-3">mdi-web</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('web') }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon color="grey">mdi-open-in-new</v-icon>
      </v-list-item-action>
    </v-list-item>

    <v-list-item target="_blank"
                 v-bind:href="instagramUrl">
      <v-list-item-icon>
        <v-icon color="accent lighten-3">mdi-instagram</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('instagram') }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon color="grey">mdi-open-in-new</v-icon>
      </v-list-item-action>
    </v-list-item>

    <v-list-item v-bind:href="`mailto:${email}`">
      <v-list-item-icon>
        <v-icon color="accent lighten-3">mdi-email</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('email') }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon color="grey">mdi-pencil-box-outline</v-icon>
      </v-list-item-action>
    </v-list-item>

    <v-divider />

    <v-list-item v-bind:to="{ name: 'licences' }">
      <v-list-item-icon>
        <v-icon>mdi-information</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('licences') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'imprint' }">
      <v-list-item-icon>
        <v-icon>mdi-scale-balance</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('imprint') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'privacy-policy' }">
      <v-list-item-icon>
        <v-icon>mdi-shield-account</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('privacyPolicy') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
 import settings from '@/settings'

  export default {
    computed: {
      email() {
        return settings.email
      },
      instagramUrl() {
        return settings.instagramUrl
      },
      webUrl() {
        return settings.webUrl
      }
    },
    name: 'PrimaryNavigation'
  }
</script>

<i18n>
  {
    "de": {
      "email": "E-Mail",
      "imprint": "Impressum",
      "instagram": "Instagram",
      "licences": "Lizenzen",
      "list": "Liste",
      "partners": "Partner",
      "map": "Karte",
      "privacyPolicy": "Datenschutzerklärung",
      "spots": "Spots",
      "web": "Webseite"
    },
    "en": {
      "email": "Email",
      "imprint": "Imprint",
      "instagram": "Instagram",
      "licences": "Licences",
      "list": "List",
      "partners": "Partners",
      "map": "Map",
      "privacyPolicy": "Privacy policy",
      "spots": "Spots",
      "web": "Web"
    }
  }
</i18n>