<template>
  <v-list class="py-0"
          color="secondary"
          dark>
    <v-list-item class="deep-orange lighten-1"
                 exact
                 v-bind:to="{ name: 'home' }">
      <v-list-item-icon>
        <v-icon>mdi-chevron-left</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('back') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'administration-food-trucks' }">
      <v-list-item-icon>
        <v-icon>mdi-truck</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('foodTrucks') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'administration-partners' }"
                 v-if="hasRole('FoodTruckFinderAdministrator')">
      <v-list-item-icon>
        <v-icon>mdi-handshake</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('partners') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'administration-categories' }"
                 v-if="hasRole('FoodTruckFinderAdministrator')">
      <v-list-item-icon>
        <v-icon>mdi-tag</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('categories') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'administration-spots' }"
                 v-if="hasRole('FoodTruckFinderAdministrator')">
      <v-list-item-icon>
        <v-icon>mdi-fire</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('spots') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        hasRole: 'auth/hasRole'
      })
    },
    name: 'AdministrationNavigation'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "categories": "Kategorien",
      "foodTrucks": "Foodtrucks",
      "partners": "Partner",
      "spots": "Spots"
    },
    "en": {
      "back": "Back",
      "categories": "Categories",
      "foodTrucks": "Food trucks",
      "partners": "Partners",
      "spots": "Spots"
    }
  }
</i18n>