import Vue from 'vue'
import Vuex from 'vuex'

import { createAuthStoreModule } from 'kayleen-vue-auth0-client'

import module from './module'

import auth0Settings from '../auth0-settings'

import AttachmentService from '../api/attachment-service'
import CategoryService from '../api/category-service'
import FoodTruckService from '../api/food-truck-service'
import PartnerService from '../api/partner-service'
import MapService from '../api/map-service'
import SpotService from '../api/spot-service'

import settings from '../settings'

const attachmentService = new AttachmentService(settings.apiUrl)
const categoryService = new CategoryService(settings.apiUrl)
const foodTruckService = new FoodTruckService(settings.apiUrl)
const partnerService = new PartnerService(settings.apiUrl)
const mapService = new MapService()
const spotService = new SpotService(settings.apiUrl)

Vue.use(Vuex)

export default new Vuex.Store({
  actions: {
    // attachment
    // ==========
    async createAttachment({ rootGetters }, { data }) {
      const accessToken = rootGetters['auth/accessToken']

      return await attachmentService.create(data, { accessToken })
    },

    // category
    // ========
    async createCategory({ commit, rootGetters }, { category }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await categoryService.create(category, { accessToken })

      category.id = result.id

      commit('createCategory', { category })
    },
    async deleteCategory({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await categoryService.delete(id, { accessToken })

      if (result) {
        commit('deleteCategory', { id })
      }
    },
    async loadCategories({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const categories = await categoryService.getAll({ accessToken })

      commit('setCategories', { categories })
    },
    async updateCategory({ commit, rootGetters }, { category }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await categoryService.update(category.id, category, { accessToken })

      if (result) {
        commit('updateCategory', { category })
      }
    },

    // foodTruck
    // =========
    async createFoodTruck({ commit, rootGetters }, { foodTruck }) {
      const accessToken = rootGetters['auth/accessToken']

      foodTruck.timeSlots = await Promise.all(foodTruck.timeSlots.map(async t => {
        try {
          const coordinate = await mapService.lookup(`${t.streetAddress}, ${t.postalCode} ${t.city}`)

          return {
            ...t,
            latitude: coordinate.latitude,
            longitude: coordinate.longitude
          }
        } catch {
          return t
        }
      }))

      /*const coordinate = await mapService.lookup(`${foodTruck.streetAddress}, ${foodTruck.postalCode} ${foodTruck.city}`)

      if (coordinate != null) {
        foodTruck.latitude = coordinate.latitude
        foodTruck.longitude = coordinate.longitude
      }*/

      const result = await foodTruckService.create(foodTruck, { accessToken })

      foodTruck.id = result.id

      commit('createFoodTruck', { foodTruck })
    },
    async deleteFoodTruck({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await foodTruckService.delete(id, { accessToken })

      if (result) {
        commit('deleteFoodTruck', { id })
      }
    },
    async loadFoodTrucks({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const foodTrucks = await foodTruckService.getAll({ accessToken })

      commit('setFoodTrucks', { foodTrucks })
    },
    async updateFoodTruck({ commit, rootGetters }, { foodTruck }) {
      const accessToken = rootGetters['auth/accessToken']

      foodTruck.timeSlots = await Promise.all(foodTruck.timeSlots.map(async t => {
        try {
          const coordinate = await mapService.lookup(`${t.streetAddress}, ${t.postalCode} ${t.city}`)

          return {
            ...t,
            latitude: coordinate.latitude,
            longitude: coordinate.longitude
          }
        } catch {
          return t
        }
      }))

      /*const coordinate = await mapService.lookup(`${foodTruck.streetAddress}, ${foodTruck.postalCode} ${foodTruck.city}`)

      if (coordinate != null) {
        foodTruck.latitude = coordinate.latitude
        foodTruck.longitude = coordinate.longitude
      }*/

      const result = await foodTruckService.update(foodTruck.id, foodTruck, { accessToken })

      if (result) {
        commit('updateFoodTruck', { foodTruck })
      }
    },



    // partner
    // =======
    async createPartner({ commit, rootGetters }, { partner }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await partnerService.create(partner, { accessToken })

      partner.id = result.id

      commit('createPartner', { partner })
    },
    async deletePartner({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await partnerService.delete(id, { accessToken })

      if (result) {
        commit('deletePartner', { id })
      }
    },
    async loadPartners({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const partners = await partnerService.getAll({ accessToken })

      commit('setPartners', { partners })
    },
    async updatePartner({ commit, rootGetters }, { partner }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await partnerService.update(partner.id, partner, { accessToken })

      if (result) {
        commit('updatePartner', { partner })
      }
    },

    // spot
    // ====
    async createSpot({ commit, rootGetters }, { spot }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await spotService.create(spot, { accessToken })

      spot.id = result.id

      commit('createSpot', { spot })
    },
    async deleteSpot({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await spotService.delete(id, { accessToken })

      if (result) {
        commit('deleteSpot', { id })
      }
    },
    async loadSpots({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const spots = await spotService.getAll({ accessToken })

      commit('setSpots', { spots })
    },
    async updateSpot({ commit, rootGetters }, { spot }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await spotService.update(spot.id, spot, { accessToken })

      if (result) {
        commit('updateSpot', { spot })
      }
    },

    // TODO
    async searchPlace({ commit, getters }, { query }) {
      return await mapService.search(query)
    },
  },
  getters: {
    attachmentUrl: state => id => `${settings.apiUrl}/attachment/${id}/content`,
    category: state => id => state.categories.find(c => c.id === id),
    categoryColor: (state, getters) => id => getters.category(id)?.color,
    categoryIcon: (state, getters) => id => getters.category(id)?.icon,
    categoryTitle: (state, getters) => id => getters.category(id)?.title,
    categories: state => state.categories.sort((a, b) => a.title.localeCompare(b.title)),
    daysOfWeek: state => state.daysOfWeek,
    daysOfWeekLabel: state => value => state.daysOfWeek.find(d => d.value === value)?.label,
    filteredFoodTrucks: (state, getters, rootState, rootGetters) => {
      const user = rootGetters['auth/user']

      if (user == null) {
        return getters.foodTrucks
      }

      if (rootGetters['auth/hasRole']('FoodTruckFinderAdministrator')) {
        return getters.foodTrucks
      }

      const foodTruckIds = user['https://www.wachter.biz/foodTruckIds']

      if (foodTruckIds == null || foodTruckIds.length <= 0) {
        return getters.foodTrucks
      }

      return getters.foodTrucks.filter(f => foodTruckIds.includes(f.id))
    },
    foodIcons: state => state.foodIcons,
    foodTruck: state => id => state.foodTrucks.find(f => f.id === id),
    foodTrucks: state => state.foodTrucks.sort((a, b) => a.title.localeCompare(b.title)),
    partner: state => id => state.partners.find(f => f.id === id),
    partners: state => state.partners.sort((a, b) => a.title.localeCompare(b.title)),
    spot: state => id => state.spots.find(s => s.id === id),
    spots: state => state.spots.sort((a, b) => a.title.localeCompare(b.title))
  },
  modules: {
    auth: createAuthStoreModule(auth0Settings),
    module: module
  },
  mutations: {
    // category
    // ========
    createCategory(state, { category }) {
      state.categories.push(category)
    },
    deleteCategory(state, { id }) {
      const index = state.categories.findIndex(c => c.id === id)

      if (index !== -1) {
        state.categories.splice(index, 1)
      }
    },
    setCategories(state, { categories }) {
      state.categories = categories
    },
    updateCategory(state, { category }) {
      const index = state.categories.findIndex(c => c.id === category.id)

      Vue.set(state.categories, index, category)
    },

    // foodTruck
    // =========
    createFoodTruck(state, { foodTruck }) {
      state.foodTrucks.push(foodTruck)
    },
    deleteFoodTruck(state, { id }) {
      const index = state.foodTrucks.findIndex(f => f.id === id)

      if (index !== -1) {
        state.foodTrucks.splice(index, 1)
      }
    },
    setFoodTrucks(state, { foodTrucks }) {
      state.foodTrucks = foodTrucks
    },
    updateFoodTruck(state, { foodTruck }) {
      const index = state.foodTrucks.findIndex(f => f.id === foodTruck.id)

      Vue.set(state.foodTrucks, index, foodTruck)
    },

    // partner
    // =========
    createPartner(state, { partner }) {
      state.partners.push(partner)
    },
    deletePartner(state, { id }) {
      const index = state.partners.findIndex(p => p.id === id)

      if (index !== -1) {
        state.partners.splice(index, 1)
      }
    },
    setPartners(state, { partners }) {
      state.partners = partners
    },
    updatePartner(state, { partner }) {
      const index = state.partners.findIndex(p => p.id === partner.id)

      Vue.set(state.partners, index, partner)
    },

    // spot
    // ====
    createSpot(state, { spot }) {
      state.spots.push(spot)
    },
    deleteSpot(state, { id }) {
      const index = state.spots.findIndex(s => s.id === id)

      if (index !== -1) {
        state.spots.splice(index, 1)
      }
    },
    setSpots(state, { spots }) {
      state.spots = spots
    },
    updateSpot(state, { spot }) {
      const index = state.spots.findIndex(s => s.id === spot.id)

      Vue.set(state.spots, index, spot)
    },
  },
  state: {
    categories: [],
    daysOfWeek: [
      {
        label: 'global.monday',
        value: 1
      },
      {
        label: 'global.tuesday',
        value: 2
      },
      {
        label: 'global.wednesday',
        value: 3
      },
      {
        label: 'global.thursday',
        value: 4
      },
      {
        label: 'global.friday',
        value: 5
      },
      {
        label: 'global.saturday',
        value: 6
      },
      {
        label: 'global.sunday',
        value: 7
      }
    ],
    foodIcons: [
      'baguette',
      'barley',
      'beer',
      'beer-outline',
      'blender',
      'blender-outline',
      'bottle-soda',
      'bottle-soda-classic',
      'bottle-soda-outline',
      'bottle-wine',
      'bottle-wine-outline',
      'bowl',
      'bowl-mix',
      'bowl-mix-outline',
      'bowl-outline',
      'bread-slice',
      'bread-slice-outline',
      'cake',
      'cake-layered',
      'cake-variant',
      'cake-variant-outline',
      'candy',
      'candy-off',
      'candy-off-outline',
      'candy-outline',
      'candycane',
      'carrot',
      'cheese',
      'cheese-off',
      'chili-hot',
      'chili-medium',
      'chili-mild',
      'chili-off',
      'coffee',
      'coffee-maker',
      'coffee-maker-check',
      'coffee-maker-check-outline',
      'coffee-off',
      'coffee-off-outline',
      'coffee-outline',
      'coffee-to-go',
      'coffee-to-go-outline',
      'cookie',
      'cookie-alert',
      'cookie-alert-outline',
      'cookie-check',
      'cookie-check-outline',
      'cookie-clock',
      'cookie-clock-outline',
      'cookie-cog',
      'cookie-cog-outline',
      'cookie-edit',
      'cookie-edit-outline',
      'cookie-lock',
      'cookie-lock-outline',
      'cookie-minus',
      'cookie-minus-outline',
      'cookie-off',
      'cookie-off-outline',
      'cookie-outline',
      'cookie-plus',
      'cookie-plus-outline',
      'cookie-refresh',
      'cookie-refresh-outline',
      'cookie-remove',
      'cookie-remove-outline',
      'cookie-settings',
      'cookie-settings-outline',
      'corn',
      'corn-off',
      'cow-off',
      'cube-off-outline',
      'cube-outline',
      'cup',
      'cup-off',
      'cup-off-outline',
      'cup-outline',
      'cup-water',
      'cupcake',
      'egg',
      'egg-fried',
      'egg-off',
      'egg-off-outline',
      'egg-outline',
      'fish',
      'fish-off',
      'food',
      'food-apple',
      'food-apple-outline',
      'food-croissant',
      'food-drumstick',
      'food-drumstick-off',
      'food-drumstick-off-outline',
      'food-drumstick-outline',
      'food-fork-drink',
      'food-halal',
      'food-hot-dog',
      'food-kosher',
      'food-off',
      'food-off-outline',
      'food-outline',
      'food-steak',
      'food-steak-off',
      'food-takeout-box',
      'food-takeout-box-outline',
      'food-turkey',
      'food-variant',
      'food-variant-off',
      'french-fries',
      'fruit-cherries',
      'fruit-cherries-off',
      'fruit-citrus',
      'fruit-citrus-off',
      'fruit-grapes',
      'fruit-grapes-outline',
      'fruit-pear',
      'fruit-pineapple',
      'fruit-watermelon',
      'glass-cocktail',
      'glass-cocktail-off',
      'glass-flute',
      'glass-fragile',
      'glass-mug',
      'glass-mug-off',
      'glass-mug-variant',
      'glass-mug-variant-off',
      'glass-pint-outline',
      'glass-stange',
      'glass-tulip',
      'glass-wine',
      'grill',
      'grill-outline',
      'hamburger',
      'hamburger-check',
      'hamburger-minus',
      'hamburger-off',
      'hamburger-plus',
      'hamburger-remove',
      'hops',
      'ice-cream',
      'noodles',
      'sausage',
      'sausage-off',
      'ice-pop',
      'leaf',
      'leaf-maple'
    ],
    foodTrucks: [],
    partners: [],
    spots: []
  }
})